import Vue from 'vue';
import Router from 'vue-router';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

Vue.use(Router);

export const routes = [
  {
    path: '/',
    name: 'home',
    // component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/home/Home'),
    component: () => import('@/components/bonauf/home/AppDealHome'),
    meta: { title: 'Home' },
  },
  {
    path: '/deal-filter/app',
    name: 'deal-filter-app',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bonauf/filterDeal/DealFilterMobile'),
    meta: { title: 'App Deal Filter' },
  },
  {
    path: '/deal-filter/app-desktop',
    name: 'deal-filter-desktop',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bonauf/filterDeal/DealFilterDesktop'),
    meta: { title: 'App Deal Filter' },
  },
  {
    path: '/deal-tab/app',
    name: 'deal-tab-app',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bonauf/dealTabs/DealTabMobile'),
    meta: { title: 'App Deal Tab' },
  },
  {
    path: '/deal-tab/app-desktop',
    name: 'deal-tab-desktop',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bonauf/dealTabs/DealTabDesktop'),
    meta: { title: 'App Deal Tab' },
  },
  {
    path: '/check-booking',
    name: 'check-booking',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/checkBooking/CheckBooking'),
    meta: { title: 'Check Booking' },
  },
  {
    path: '/product-page',
    name: 'product-page',
    // component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/ProductPage'),
    meta: { title: 'Product Page' },
    redirect: (to) => {
      const { channel } = to.query;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      let toPath = { path: '/product-page/web' };
      if (channel === VUE_APP_CHANNEL_MOBILE_APP) {
        if(isMobile) {
          toPath = { path: '/product-page/app' };
        } else {
          toPath = {path: '/product-page/app-desktop'};
        }
      }
      return toPath;
    },
  },
  {
    path: '/product-page/web',
    name: 'product-page-web',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/page/ProductPage'),
    meta: { title: 'Product Page' },
  },
  {
    path: '/product-page/oldapp',
    name: 'product-page-old-app',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/page/ProductPage'),
    meta: { title: 'Product Page' },
  },
  {
    path: '/product-page/app',
    name: 'product-page-app',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/page/ProductPageApp'),
    meta: { title: 'Product Page' },
  },
  {
    path: '/product-page/app-desktop',
    name: 'product-page-app-desktop',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/page/ProductPageAppDesktop'),
    meta: { title: 'Product Page' },
  },
  {
    path: '/product-hotelbeds',
    name: 'product-hotelbeds',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/productPage/ProductHotelbeds'),
    meta: { title: 'Product Hotelbeds' },
  },
  {
    path: '/flight-only',
    name: 'flight-only',
    // component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingFlightOnly/FlightOnly'),
    meta: { title: 'Flight Only' },
    redirect: (to) => {
      const { channel } = to.query;
      let toPath = { path: '/flight-only/web' };
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (channel === VUE_APP_CHANNEL_MOBILE_APP) {
        if(isMobile) {
          toPath = { path: '/flight-only/app' };
        } else {
          toPath = {path: '/flight-only/app-desktop'};
        }
      }
      return toPath;
    },
  },
  {
    path: '/flight-only/web',
    name: 'flight-only-web',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingFlightOnly/page/FlightOnly'),
    meta: { title: 'Flight Only' },
  },
  {
    path: '/flight-only/oldapp',
    name: 'flight-only-old-app',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingFlightOnly/page/FlightOnly'),
    meta: { title: 'Flight Only' },
  },
  {
    path: '/flight-only/app',
    name: 'flight-only-app',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingFlightOnly/page/FlightOnlyApp'),
    meta: { title: 'Flight Only' },
  },
  {
    path: '/flight-only/app-desktop',
    name: 'flight-only-app-desktop',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/bookingFlightOnly/page/FlightOnlyAppDesktop'),
    meta: { title: 'Flight Only' },
  },
  {
    path: '/destination-list',
    name: 'destination-list',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/TravelInfoPage/chooseDestHotel/DestinationList'),
    meta: { title: 'Destination List' },
  },
  {
    path: '/hotel-list',
    name: 'hotel-list',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/TravelInfoPage/chooseDestHotel/HotelList'),
    meta: { title: 'Destination List' },
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Booking'),
    meta: { title: 'Booking Page' },
    children: [
      {
        path: 'hotel-flight/web',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/hotelFlight/HotelFlight'),
        name: 'booking-hotel-flight-web',
        meta: { title: 'Booking Hotel & Flight' },
      },
      {
        path: 'hotel-flight/oldapp',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/hotelFlight/HotelFlight'),
        name: 'booking-hotel-flight-old-app',
        meta: { title: 'Booking Hotel & Flight' },
      },
      {
        path: 'hotel-flight/app',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/hotelFlight/HotelFlightApp'),
        name: 'booking-hotel-flight-app',
        meta: { title: 'Booking Hotel & Flight' },
      },
      {
        path: 'hotel-flight/app-desktop',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/hotelFlight/HotelFlightAppDesktop'),
        name: 'booking-hotel-flight-app-desktop',
        meta: { title: 'Booking Hotel & Flight' },
      },
      {
        path: 'verifying-card',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/VerifyingCard'),
        name: 'verifying-card',
        meta: { title: 'Verifying Card' },
      },
      {
        path: 'card-verified',
        // component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/cardVerified/CardVerified'),
        name: 'card-verified',
        meta: { title: 'Card Verified' },
        redirect: (to) => {
          const { channel } = to.query;
          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          let toPath = { path: '/booking/card-verified/web' };
          if (channel === VUE_APP_CHANNEL_MOBILE_APP) {
            if(isMobile) {
              toPath = { path: '/booking/card-verified/app' };
            } else {
              toPath = { path: '/booking/card-verified/app-desktop' };
            }
          }
          return toPath;
        },
      },
      {
        path: 'card-verified/web',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/cardVerified/CardVerified'),
        name: 'card-verified-web',
        meta: { title: 'Card Verified' },
      },
      {
        path: 'card-verified/oldapp',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/cardVerified/CardVerified'),
        name: 'card-verified-old-app',
        meta: { title: 'Card Verified' },
      },
      {
        path: 'card-verified/app',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/cardVerified/CardVerifiedApp'),
        name: 'card-verified-app',
        meta: { title: 'Card Verified' },
      },
      {
        path: 'card-verified/app-desktop',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/cardVerified/CardVerifiedAppDesktop'),
        name: 'card-verified-app-desktop',
        meta: { title: 'Card Verified' },
      },
      {
        path: 'flight/web',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/flight/Flight'),
        name: 'booking-flight-web',
        meta: { title: 'Booking Flight' },
      },
      {
        path: 'flight/oldapp',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/flight/Flight'),
        name: 'booking-flight-old-app',
        meta: { title: 'Booking Flight' },
      },
      {
        path: 'flight/app',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/flight/FlightApp'),
        name: 'booking-flight-app',
        meta: { title: 'Booking Flight' },
      },
      {
        path: 'flight/app-desktop',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/flight/FlightAppDesktop'),
        name: 'booking-flight-app-desktop',
        meta: { title: 'Booking Flight' },
      },
      {
        path: 'hotelbeds',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Hotelbeds'),
        name: 'booking-hotelbeds',
        meta: { title: 'Booking Hotelbeds' },
      },
    ],
  },
  {
    path: '/app-deal',
    name: 'app-deal',
    component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/deal/AppDeal'),
    meta: {
      title: 'השטיח המעופף - חבילות נופש, טיסות לחו"ל, חבילות',
    },
    children: [
      {
        path: 'shareAppLink',
        name: 'shareAppLink',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/deal/ShareAppLink'),
      },
      {
        path: 'hotel',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/deal/HotelTab'),
        name: 'deal-hotel-tab',
      },
      {
        path: 'flight',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/deal/FlightTab'),
        name: 'deal-flight-tab',
      },
      {
        path: 'roomTables',
        component: () => import(/* webpackChunckName: 'user-chunk' */ '@/components/deal/RoomTableTab'),
        name: 'deal-room-table-tab',
      },
      {
        path: 'itinerary',
        component: () => import(/* webpackChunckName: 'user-chunk' */ '@/components/deal/ItineraryTab'),
        name: 'itinerary-tab',
      },
    ],
  },

  {
    path: '/price-plan',
    name: 'price-plan',
    component: () => import(/* webpackChunkName: "user-chunk" */ '@/components/pricePlan/PricePlan'),
    meta: { title: 'Price Plan' },
  },
  {
    path: '/price-plan-changes',
    component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/pricePlan/ChangedPricePlan'),
    name: 'price-plan-changes',
    meta: { title: 'Price Changed Deals' },
    children: [
      {
        path: 'added',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/pricePlan/ChangedSubPage'),
        name: 'added-deals',
        meta: { title: 'Added deals', type: 'added' },
      },
      {
        path: 'deleted',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/pricePlan/ChangedSubPage'),
        name: 'deleted-deals',
        meta: { title: 'New Added deals', type: 'deleted' },
      },
      {
        path: 'updated',
        component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/pricePlan/ChangedSubPage'),
        name: 'updated-deals',
        meta: { title: 'New Added deals', type: 'updated' },
      },
    ],
  },
  {
    path: '/redirect-verified',
    component: () => import(/* webpackChunkName: 'user-chunk' */ '@/components/booking/Redirect'),
    name: 'redirect-verified',
    meta: { title: 'Card Verified' },
  },
  {
    path: '/error-404',
    name: '404',
    component: () => import(/* webpackChunkName: "error-chunk" */ '@/components/404'),
    meta: { title: '404' },
  },
  {
    path: '/:slug',
    name: 'slug-info',
    component: () => import(/* webpackChunkName: "error-chunk" */ '@/components/TravelInfoPage/TravelInfo'),
    // meta: { title: 'info' },
    meta: {
      title: 'info',
      sitemap: {
        slugs: [
          '%D7%A6%D7%95%D7%A8_%D7%A7%D7%A9%D7%A8',
          '%D7%94%D7%A8%D7%A9%D7%9E%D7%94_%D7%9C%D7%A8%D7%A9%D7%99%D7%9E%D7%AA_%D7%AA%D7%A4%D7%95%D7%A6%D7%94',
          '%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%94%D7%96%D7%9E%D7%A0%D7%94',
          '%D7%9E%D7%93%D7%A8%D7%99%D7%9A_%D7%94%D7%99%D7%A2%D7%93%D7%99%D7%9D',
          'D7%90%D7%95%D7%93%D7%95%D7%AA_',
          '%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA_%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA',
          '%D7%9E%D7%99%D7%93%D7%A2_%D7%9C%D7%A0%D7%95%D7%A1%D7%A2',
          '%D7%AA%D7%95%D7%91%D7%A2%D7%A0%D7%95%D7%AA_%D7%99%D7%99%D7%A6%D7%95%D7%92%D7%99%D7%AA',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99',
          '%D7%97%D7%95%D7%A7_%D7%A9%D7%A8%D7%95%D7%AA%D7%99_%D7%94%D7%AA%D7%A2%D7%95%D7%A4%D7%94',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%9B%D7%A8%D7%98%D7%99%D7%A1%D7%99_%D7%98%D7%99%D7%A1%D7%94',
          '%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%95%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA_%D7%91%D7%98%D7%99%D7%95%D7%9C%D7%99%D7%9D_%D7%9E%D7%90%D7%95%D7%A8%D7%92%D7%A0%D7%99%D7%9D',
          '%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%9B%D7%9C%D7%9C%D7%99%D7%9D_%D7%95%D7%94%D7%92%D7%91%D7%9C%D7%AA_%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_FLYING_SAFE',
          '%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%99%D7%95%D7%A6%D7%90%D7%99%D7%9D_%D7%9E%D7%99%D7%A9%D7%A8%D7%90%D7%9C_%D7%95%D7%9C%D7%97%D7%95%D7%96%D7%A8%D7%99%D7%9D_%D7%9C%D7%99%D7%A9%D7%A8%D7%90%D7%9C',
          '%D7%91%D7%98%D7%95%D7%9E%D7%99_%D7%A7%D7%A0%D7%99%D7%95%D7%AA_%D7%91%D7%91%D7%98%D7%95%D7%9E%D7%99_%D7%94%D7%A9%D7%98%D7%99%D7%97_%D7%94%D7%9E%D7%A2%D7%95%D7%A4%D7%A3',
          '%D7%93%D7%99%D7%9C%D7%99%D7%9D_%D7%9C%D7%91%D7%98%D7%95%D7%9E%D7%99',
          '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%90%D7%A0%D7%98%D7%9C%D7%99%D7%94',
          '%D7%98%D7%99%D7%95%D7%9C%D7%99%D7%9D_%D7%9E%D7%90%D7%95%D7%A8%D7%92%D7%A0%D7%99%D7%9D_%D7%9C%D7%90%D7%99%D7%A8%D7%95%D7%A4%D7%94',
          '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%9B%D7%A8%D7%AA%D7%99%D7%9D',
          '%D7%A7%D7%A0%D7%99%D7%95%D7%AA_%D7%91%D7%A0%D7%90%D7%A4%D7%95%D7%9C%D7%99',
          '%D7%95%D7%A8%D7%A0%D7%94_%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%95%D7%A8%D7%A0%D7%94_%D7%94%D7%A9%D7%98%D7%99%D7%97_%D7%94%D7%9E%D7%A2%D7%95%D7%A4%D7%A3',
          '%D7%93%D7%99%D7%9C%D7%99%D7%9D_%D7%9C%D7%90%D7%99%D7%A8%D7%95%D7%A4%D7%94',
          '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A1%D7%95%D7%A4%D7%95%D7%A8%D7%98',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%9E%D7%95%D7%A2%D7%93%D7%95%D7%9F_%D7%9C%D7%A7%D7%95%D7%97%D7%95%D7%AA_%D7%94%D7%90%D7%AA%D7%A8',
        ],
      },
    },
  },
  {
    path: '/*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "error-chunk" */ '@/components/404'),
    meta: { title: '500' },
  },
];

export default new Router({
  mode: 'history',
  routes,
});
