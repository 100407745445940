<template>
  <section class="plaindetails-area">
    <div class="container">
      <div class="plaindetails-box" v-if="type === 'depature'">
        <h5 class="text-center" v-if="type === 'depature'">{{ $t("flight-only.round-trip-departure") }}</h5>
        <figure class="logo"><img :src="markAirline" alt="" title="" /></figure>
        <div class="plain-date">
          <div class="d-flex justify-content-between align-items-center">
            <div class="box">
              <h3>{{departHour}}</h3>
              <!-- <h4>{{ fromAirportName }}</h4> -->
              <h4>{{ fromDestName }}</h4>
            </div>
            <figure class="flight-box">
              <img :src="`${speedSizeDomain}/assets/img/app/flight-img1.png`" alt="#" title="" />
            </figure>
            <div class="box">
              <h3>{{ arriveHour }}</h3>
              <!-- <h4>{{ arriveAirportName }}</h4> -->
              <h4>{{ toDestName }}</h4>
            </div>
          </div>
        </div>
        <p><span>{{ fromAirportName }}<br/>{{ airlineNumber }}</span></p>
        <ul class="departure-list">
          <li>{{ $t("flight-only.departure-message", {time: departHour, date: departDate, airport: fromAirportName, terminal: fromTerminal}) }}</li>
          <li v-if="isConnectFlight">{{ stopoverMessage }}</li>
          <li v-if="isConnectFlight">{{ $t("flight-only.landing-message-via", { viaRouteName, viaDepTime, time: arriveHour, date: arriveDate, airport: arriveAirportName, terminal: arriveTerminal}) }}</li>
          <li v-else>{{ $t("flight-only.landing-message", { time: arriveHour, date: arriveDate, airport: arriveAirportName, terminal: arriveTerminal }) }}</li>
        </ul>
        <div class="btn-box">
          <button class="btn-primary" v-if="data && data.Authorization_msg.includes('לא אושרו')">{{ $t("flight-only.flight-no") }}: N/A</button>
          <button class="btn-primary" v-else>{{ $t("flight-only.flight-no") }}: {{ airlineNumber }}</button>
        </div>
        <div class="baggagedetails-box">
          <h4>{{ $t("flight-only.baggage-details-title") }}</h4>
          <ul>
              <li v-if="suitcaseCostIncluded">
                  <img :src="`${speedSizeDomain}/assets/img/img11.png`" alt="suitcase" class="img" />
                  {{$t("flight-only.suitcase-included", {weight: maxSuitcaseWeight})}}
              </li>
              <li v-else>
                  <img :src="`${speedSizeDomain}/assets/img/img11-no.png`" alt="suitcase" class="img" />
                  {{$t("flight-only.suitcase-not-included", {weight: maxSuitcaseWeight})}}
              </li>
              <li v-if="trolleyCostIncluded">
                  <img :src="`${speedSizeDomain}/assets/img/img12.png`" alt="trolley" class="img" />
                  {{$t("flight-only.trolley-included", {weight: maxTrolleyWeight})}}
              </li>
              <li v-else>
                  <img :src="`${speedSizeDomain}/assets/img/img14.png`" alt="trolley" class="img" />
                  {{$t("flight-only.trolley-not-included")}}
              </li>
          </ul>
        </div>
        <div class="btn-box btn-box2">
          <button class="btn-primary">{{ authorMessage }}</button>
        </div>
      </div>
      <div class="plaindetails-box" v-else>
        <h5 class="text-center" v-if="type === 'return'">{{ $t("flight-only.round-trip-return") }}</h5>
        <figure class="logo"><img :src="markAirline" alt="" title="" /></figure>
        <div class="plain-date">
          <div class="d-flex justify-content-between align-items-center">
            <div class="box">
              <h3>{{ arriveHour }}</h3>
              <!-- <h4>{{ arriveAirportName }}</h4> -->
              <h4>{{ toDestName }}</h4>
            </div>
            <figure class="flight-box">
              <img class="arrive" :src="`${speedSizeDomain}/assets/img/app/flight-img1.png`" alt="#" title="" />
            </figure>
            <div class="box">
              <h3>{{departHour}}</h3>
              <!-- <h4>{{ fromAirportName }}</h4> -->
              <h4>{{ fromDestName }}</h4>
            </div>
          </div>
        </div>
        <p><span>{{ arriveAirportName }}<br/>{{ airlineNumber }}</span></p>
        <ul class="departure-list">
          <li>{{ $t("flight-only.departure-message", {time: departHour, date: departDate, airport: fromAirportName, terminal: fromTerminal}) }}</li>
          <li v-if="isConnectFlight">{{ stopoverMessage }}</li>
          <li v-if="isConnectFlight">{{ $t("flight-only.landing-message-via", { viaRouteName, viaDepTime, time: arriveHour, date: arriveDate, airport: arriveAirportName, terminal: arriveTerminal}) }}</li>
          <li v-else>{{ $t("flight-only.landing-message", { time: arriveHour, date: arriveDate, airport: arriveAirportName, terminal: arriveTerminal }) }}</li>
        </ul>
        <div class="btn-box">
          <button class="btn-primary" v-if="data && data.Authorization_msg.includes('לא אושרו')">{{ $t("flight-only.flight-no") }}: N/A</button>
          <button class="btn-primary" v-else>{{ $t("flight-only.flight-no") }}: {{ airlineNumber }}</button>
        </div>
        <div class="baggagedetails-box">
          <h4>{{ $t("flight-only.baggage-details-title") }}</h4>
          <ul>
              <li v-if="suitcaseReturnCostIncluded">
                  <img :src="`${speedSizeDomain}/assets/img/img11.png`" alt="suitcase" class="img" />
                  {{$t("flight-only.suitcase-included", {weight: maxSuitcaseWeight})}}
              </li>
              <li v-else>
                  <img :src="`${speedSizeDomain}/assets/img/img11-no.png`" alt="suitcase" class="img" />
                  {{$t("flight-only.suitcase-not-included", {weight: maxSuitcaseWeight})}}
              </li>
              <li v-if="trolleyReturnCostIncluded">
                  <img :src="`${speedSizeDomain}/assets/img/img12.png`" alt="trolley" class="img" />
                  {{$t("flight-only.trolley-included", {weight: maxTrolleyWeight})}}
              </li>
              <li v-else>
                  <img :src="`${speedSizeDomain}/assets/img/img14.png`" alt="trolley" class="img" />
                  {{$t("flight-only.trolley-not-included")}}
              </li>
          </ul>
        </div>
        <div class="btn-box btn-box2">
          <button class="btn-primary">{{ authorMessage }}</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    airInfo: {
      type: Object,
      default: null,
    },
    baggagePriceInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      markAirline: `${this.speedSizeDomain}/assets/img/flying.png`,
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
    }),
    airlineName() {
      return this.data?.arl_Name ?? '';
    },
    fromRoute() {
      return this.data?.FL_From_Route ?? '';
    },
    fromDestName() {
      return this.getCityName(this.fromRoute);
    },
    departHour() {
      return this.getTimeFormatDirectly(this.data?.FL_Dep_Hour ?? '');
    },
    departDate() {
      return this.data ? this.getDateFormat(this.data?.FL_Date) : '';
    },
    departWeek() {
      return this.data ? this.getWeek(this.data?.FL_Date) : '';
    },
    fromAirportName() {
      return this.getAirportName(this.data?.FL_From_Air_Port || '');
    },
    toRoute() {
      return this.data?.FL_To_Route ?? '';
    },
    toDestName() {
      return this.getCityName(this.toRoute);
    },
    isConnectFlight() {
      return !!this.data?.FL_Via_Air_Port;
    },
    viaRouteName() {
      return this.getCityName(this.data?.FL_Flt_Via || '');
    },
    viaAirPortName() {
      return this.getAirportName(this.data?.FL_Via_Air_Port || '');
    },
    viaTerminal() {
      return this.data?.FL_Via_Terminal ?? '';
    },
    viaLandTime() {
      return this.data?.FL_ViaDep_Hour || false ? this.getTimeFormat(this.data.FL_Via_Hour) : '';
    },
    viaDepTime() {
      return this.data?.FL_ViaDep_Hour || false ? this.getTimeFormat(this.data.FL_ViaDep_Hour) : '';
    },
    viaStopTimeHours() {
      if (!this.data) return 0;
      return Number(this.data.stopOverTime.split(':')[0]);
    },
    viaStopTimeMinutes() {
      if (!this.data) return 0;
      return Number(this.data.stopOverTime.split(':')[1]);
    },
    arriveHour() {
      return this.getTimeFormatDirectly(this.data?.FL_Arrv_Hour ?? '');
    },
    arriveDate() {
      return this.data ? this.getDateFormat(this.data.landingDate) : '';
    },
    arriveWeek() {
      return this.data ? this.getWeek(this.data.landingDate) : '';
    },
    arriveAirportName() {
      return this.getAirportName(this.data?.FL_To_Air_Port || '');
    },
    airlineNumber() {
      return this.data ? `${this.data.FL_AIRLINE} - ${this.data.FL_Flt_Number}` : '';
    },
    authorMessage() {
      return this.data ? this.data.Authorization_msg : '';
    },
    fromTerminal() {
      return this.stringTerminal(this.data?.FL_From_Terminal || 'N/A');
    },
    arriveTerminal() {
      return this.stringTerminal(this.data?.FL_To_Terminal || 'N/A');
    },
    maxSuitcaseWeight() {
      return this.data?.FL_Max_Weight || 20;
    },
    maxTrolleyWeight() {
      return +this.data?.FL_Max_Weight_Trolley || 0;
    },
    stopoverMessage() {
      if (this.viaStopTimeHours > 0 && this.viaStopTimeMinutes > 0) {
        return this.$t('flight-only.stopover-message', {
          city: this.viaAirPortName,
          hours: this.viaStopTimeHours,
          minutes: this.viaStopTimeMinutes,
        });
      } else if (this.viaStopTimeHours > 0) {
        return this.$t('flight-only.stopover-message-hours-only', {
          city: this.viaAirPortName,
          hours: this.viaStopTimeHours,
        });
      } else if (this.viaStopTimeMinutes > 0) {
        return this.$t('flight-only.stopover-message-minutes-only', {
          city: this.viaAirPortName,
          minutes: this.viaStopTimeMinutes,
        });
      }
      return '';
    },
    trolleyCostIncluded() {
      if (!this.baggagePriceInfo) return true;
      return (this.baggagePriceInfo.trolleyPrice === null);
    },
    trolleyReturnCostIncluded() {
      if (!this.baggagePriceInfo) return true;
      return (this.baggagePriceInfo.trolleyReturnPrice === null);
    },
    suitcaseCostIncluded() {
      if (!this.baggagePriceInfo) return true;
        return (this.baggagePriceInfo.suitcasePrice === null);
    },
    suitcaseReturnCostIncluded() {
      if (!this.baggagePriceInfo) return true;
      return (this.baggagePriceInfo.suitcasePrice === null);
    },
  },
  watch: {
    airInfo() {
      if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
        this.markAirline = this.airInfo.desc2 || `${this.speedSizeDomain}/assets/img/flying.png`;
      }
    },
  },
  beforeMount() {
  },
  created() {
    if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
      this.markAirline = this.airInfo.desc2 || `${this.speedSizeDomain}/assets/img/flying.png`;
    }
  },
  methods: {
    getDateFormat(str) {
      return dayjs(str).format('DD.MM.YY');
    },
    getTimeFormat(str) {
      return dayjs(str).format('HH:mm');
    },
    getTimeFormatDirectly(str) {
      return dayjs(`${new Date().toISOString().split('T')[0]}T${str}`).format('HH:mm');
    },
    getDateTimeFormat(str) {
      return dayjs(str).format('DD.MM.YY hh:mm A');
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    getCityName(code) {
      return this.data?.translations?.flightDestinationName?.[code]?.[this.lang] ?? code;
    },
    getAirportName(code) {
      return this.data?.translations?.airportName[code]?.[this.lang] ?? code;
    },
    stringTerminal(pTerminal) {
      return pTerminal !== 'N/A' ? `( ${this.$t('flight-only.terminal')} ${pTerminal} )` : '';
    },
    showFlightDetail() {
      this.showDetailFlag = true;
    },
    closeDetailPopup() {
      this.showDetailFlag = false;
    },
  },
};
</script>
<style lang="less" scoped>
  .bonauf-desktop {
    .plaindetails-area {
      .plaindetails-box {
        max-width: 730px !important;
        margin: auto;

        .departure-list {
          li::after {
            top: -7px;
            right: 8px;
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
  img.return-air {
    transform: rotate(180deg);
  }
  .flight_box_baggage_body .flight_box_one_div .img_icon img {
    width: 50%;
    height: auto;
  }
  @media (max-width: 768px) {
    .flight_only_area .flight_only_body .flight_box_one .left_box .flight_icon {
      top: 0;
    }
    .flight_only_area .flight_only_body .flight_box_one .right_box .right_box_one .icon_derection {
      margin-top: -0.3rem;
    }
    .plaindetails-box h5 {
      width: 100%;
      padding: 0;
      margin: 0 0 15px;
      color: #000000;
      font-family: 'FbCoherentiSansBold';
      font-size: 25px;
    }
  }
</style>
