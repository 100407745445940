const { VUE_APP_SOCKET_ENDPOINT, VUE_APP_STAGING_SOCKET_ENDPOINT } = process.env;
let SOCKET_HOST = VUE_APP_SOCKET_ENDPOINT;
if (window.location.href.includes('staging-frontend.vercel.app')) {
  SOCKET_HOST = VUE_APP_STAGING_SOCKET_ENDPOINT;
}

const getSocketType = (event) => {
  const { url } = event.currentTarget;
  let type = '';
  if (url.includes('shared=true')) {
    type = 'shared';
  } else {
    type = 'private';
  }
  return type;
};

export default {
  state: () => ({
    socket: null,
    isConnected: false,
    privateMessage: '',
    sharedMessage: '',
    reconnectError: null,
  }),
  getters: {
    IS_SOCKET_CONNECTED: (state) => state.isConnected,
    GET_PRIVATE_MESSAGE: (state) => state.privateMessage,
    GET_SHARED_MESSAGE: (state) => state.sharedMessage,
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      const socket = state.socket || {};
      const type = getSocketType(event);
      socket[type] = event.currentTarget;

      state.socket = socket;
      state.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    SOCKET_ONMESSAGE(state, event) {
      const type = getSocketType(event);
      const message = event.data;
      if (type === 'private') {
        state.privateMessage = message;
      } else {
        state.sharedMessage = message;
      }
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state, event) {
      const reconnectError = state.reconnectError || {};
      const type = getSocketType(event);
      reconnectError[type] = true;

      state.reconnectError = reconnectError;
    },
  },
  actions: {
    SEND_MESSAGE_VIA_SOCKET: (context, message) => {
      const { socket } = context.state;
      if (socket) { socket.send(message); }
    },
    SOCKET_PRIVATE_CONNECT: (context, payload) => {
      const vm = payload;
      const { userAgencySid } = context.rootState.agencyUsers;
      const { agencyCode } = context.rootState.whiteLabel;
      vm.$connect(`${SOCKET_HOST}/agencyMessage/agentWs/?agency=${agencyCode}&agencySid=${userAgencySid}`);
    },
    SOCKET_SHARED_CONNECT: (context, payload) => {
      const vm = payload;
      const { userAgencySid } = context.rootState.agencyUsers;
      const { agencyCode } = context.rootState.whiteLabel;
      vm.$connect(`${SOCKET_HOST}/agencyMessage/agentWs/?shared=true&agency=${agencyCode}&agencySid=${userAgencySid}`);
    },
    SOCKET_DIS_CONNECT: (context, payload) => {
      const vm = payload;
      vm.$disconnect();
      const { socket } = context.state;
      socket.private.close();
      socket.shared.close();
    },
  },
};
