export default {
  methods: {
    gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    },
    availableEmitEventGA4ByDomain() {
      let availableEmit = false;
      const currDomain = window.location.host;
      if (!currDomain.includes('localhost') && !currDomain.includes('vercel')) {
        availableEmit = true;
      }
      return availableEmit;
    },
  },
};
