<template>
  <section class="banner-area">
    <h1 class="banner_heading" v-if="type === 'flight-only'">BLUE BIRD AIR</h1>
    <b-carousel
      v-model="slide"
      :interval="20000"
      indicators
      ref="myCarousel"
      class="banner-carousel"
      style="text-shadow: 1px 1px 2px #333"
      v-if="!hideDealCondition"
    >
      <b-carousel-slide
        :img-src="item"
        v-for="(item, index) in imageList"
        :key="index"
        img-width="1794"
        img-height="903"
        img-alt="slider-image"
      ></b-carousel-slide>
    </b-carousel>
    <div style="min-height: 100vh;" v-else></div>
    <SearchTabs :type="currentPage==='price-plan' ? 'price' : 'tabs'" v-if="!isTestState"/>
    <!-- <SearchTabs type="price" v-else-if="currentPage==='price-plan'"/> -->
    <search-panel-tabs type="tabs" v-else/>
  </section>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
import TemplateBanner from './TemplateBanner';

export default {
  name: 'BannerTheme0',
  components: {
    BCarousel,
    BCarouselSlide,
    SearchTabs: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
  },
  computed: {
    isTestState() {
      return this.$route.query.t === 't';
    },
  },
  data: () => ({
  }),
  created() {
  },
  extends: TemplateBanner,
};
</script>

<style>
.banner-area .carousel.banner-carousel.slide .carousel-inner .carousel-item,
.lesiurpackages.mt-5 .carousel.banner-carousel.slide .carousel-inner .carousel-item {
  float: left;
  margin-right: -100%;
  margin-left: auto;
}
.banner-area .carousel.banner-carousel.slide .carousel-inner .carousel-item.carousel-item-prev,
.lesiurpackages.mt-5 .carousel.banner-carousel.slide .carousel-inner .carousel-item.carousel-item-prev,
.banner-area .carousel.banner-carousel.slide .carousel-inner .carousel-item.carousel-item-next,
.lesiurpackages.mt-5 .carousel.banner-carousel.slide .carousel-inner .carousel-item.carousel-item-next  {
  transform: none;
}

.banner-area .carousel.banner-carousel.slide .carousel-inner .carousel-item,
.banner-area .carousel.banner-carousel.slide .carousel-inner .carousel-item .img-fluid {
  height: inherit;
}
.carousel-indicators li {
  display: inline-block;
  zoom: 1;
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;

  height: 1em;
  width: 1em;
  background-color: #ffffffa8;
  transition: all 0.5s ease-in-out;
  margin: 5px 7px;
  border-radius: 30px;
}
.carousel-indicators .active {
  background-color: #00ffff;
}
@media (max-width: 479px) {
  .banner-area .banner-carousel {
    display: none;
  }
}
@media (min-width: 1200px) {
  .banner-area .carousel-indicators {
    transform: rotate(-90deg) translateY(-50%) scale(1, -1);
    right: 0;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    display: block;
    justify-content: unset;
    padding: inherit;
    margin: auto;
  }
  .banner-area .carousel-inner {
    height: calc(100vh - 56px);
    min-height: calc(100vh - 56px);
  }
}
.banner-area .slide {
  height: auto;
}
</style>
