<template>
  <div class="apparea">
    <!----- header area start ----->
    <header class="header-area" v-if="isDesktopApp">
      <div class="container">
        <div class="d-flex justify-content-center">
          <figure class="logo bonauf-app-logo" @click="gotoHome"><img :src="`${speedSizeDomain}/assets/img/app/logo.png`" alt="#" title="" /></figure>
          <button class="btn-close pr-2"  @click="gotoProductPage">
            <i class="fa-solid fa-chevron-right ml-5"></i>
          </button>
        </div>
      </div>
    </header>
    <!----- header area stop ----->
    <div class="body_warrper">
      <section class="kremlin-area kremlin-area2" :style="perspectiveBackground">
        <div class="container">
          <mobile-head-banner-app :stage="bookingStage" />
          <h2>פרטי הטיסה</h2>
        </div>
      </section>
      <!------ plaindetails area start ------->
      <section class="plaindetails-area">
        <div class="container">
          <flight-item-app :flight="activeFlight" :baggagePriceInfo="baggagePriceInfo" :categoryName="category.code" type="booking" />
          <ticket-information v-if="category.code === 'sport_pack'" />
          <div class="p-3 mb-3 w-100 card" v-if="isOdysseySite">
            <internal-agent-info @updateInternalAgentInfo="changeAgentInfo" :currency="product.cc"
              :fullPrice="product.price" />
          </div>
          <h3>{{ $t('booking.guest-desc') }}</h3>
        </div>
      </section>
    </div>

    <section class="plainform-area">
      <div class="container">
        <user-list-flight-app :flight="flight" :index="0" @updatedUser="updatingUser" />
        <h3>{{ $t('booking.baggage') }}</h3>
      </div>
    </section>

    <section class="plainform-area">
      <div class="container">
        <baggage-list-app :userList="userList" :priceInfo="baggagePriceInfo" @updateBaggagePrice="updatingBaggagePrice"/>
        <h3 style="margin-top: 125px;">{{ $t('booking.payer-details') }}</h3>
      </div>
    </section>

    <section class="plainform-area2">
      <payer-information-app @updatePayer="updatingPayer" v-if="!bypassPaymentState" />
    </section>

    <div class="container">
      <div class="new_booking_main_area dVacation-pack booking_mobile_part mt-3">
        <div class="booking_details_three">
          <div ref="checkTerms" class="new_booking_main_area dVacation-pack booking_mobile_part mt-md-5">
            <div class="booking_details_three">
              <div class="price_details bottom">
                <div class="price_details_main_body">
                  <div class="price_details_body row">
                    <div class="terms_box">
                      <div class="form-check" v-if="!isOdysseySite">
                        <label :class="['form-check-label', 'pr-5', !checkedTerms ? 'danger' : '']" v-if="isDxbDest">
                          <input type="checkbox" class="form-check-input" v-model="checkedTerms" />
                          {{ $t('booking.i-agree-to') }}
                          <strong @click="() => showLinkInfo(`/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99`)">{{ $t('booking.agree-desc') }}</strong>
                          {{ $t('booking.read-desc') }}
                        </label>
                        <label :class="['form-check-label', 'pr-5', 'mt-2', !checkedTermsForDXB ? 'danger' : '']">
                          <span>
                            <input type="checkbox" class="form-check-input" v-model="checkedTermsForDXB" />
                          </span>
                          {{ $t('booking.confirmed-read') }}
                          <strong class="pointer" @click="() => showLinkInfo(`/%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%98%D7%A1%D7%99%D7%9D_%D7%9C%D7%93%D7%95%D7%91%D7%90%D7%99`)">
                            {{ $t('booking.dxb-terms') }}
                          </strong>
                        </label>
                      </div>
                      <div class="form-check" v-if="cancellationAllowed">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkedCancellation"
                            :disabled="!cancellationAllowed"
                          />
                          {{ $t('booking.cancellation-agree') }} <strong @click="showLinkInfo('/תקנון_פליינג_סייף_(Flying_Safe)')">{{ $t('booking.flying-safe') }}</strong>
                        </label>
                      </div>
                      <!-- <div class="form-check" v-if="!isOdysseySite">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkedPromotion"
                          />
                          {{ $t('booking.email-receive-agree') }}
                        </label>
                      </div>
                      <div class="form-check" v-if="!isOdysseySite">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkedInsurance"
                          />
                          {{ $t('booking.telephone-provide-agree') }}
                        </label>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="price_details bottom" v-if="!isOdysseySite && isMarketer && !isFcAgentMarketerMode && typeChannel !== 'App'">
                <div class="price_details_heading">
                  <h3>{{ couponTitle }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body row">
                    <div class="col-lg-4 col-12" v-for="(user, inx) in userList" :key="inx">
                      <coupon
                        :userIndex="inx"
                        :numOfPax="userList.length"
                        :checkInDate="checkInDate"
                        :currency="currency"
                        :originalPrice="originalPrice"
                        :oldShekelPrice="oldShekelPrice"
                        :user="user"
                        :couponCodes="couponCodes"
                        :couponLabels="couponLabels"
                        @updateCouponList="updateCouponList"/>
                    </div>
                  </div>
                </div>
              </div> -->
              <!----- order area start ----->
              <section class="order-area total_payment">
                <div class="container">
                  <div class="order-list">
                    <ul>
                      <li>
                        <div class="h2 mb-0 align-self-center">
                          <span class="icon" @click="showNotice()"><i
                              class="fas fa-exclamation-circle text-info"></i></span>
                        </div>
                      </li>
                      <li>
                        {{ $t('booking.total-payment') }}
                        <strong>{{ totalPriceRegular }}</strong>
                      </li>
                      <li>
                        סה”כ בשקלים
                        <strong>{{ totalPriceShekels }}</strong>
                      </li>
                    </ul>
                  </div>
                  <div class="btn-box">
                    <button class="btn-primary" @click="BookingNow" :disabled="disableBook">
                      {{ $t('search-result.book-now') }}
                    </button>
                  </div>
                </div>
              </section>
              <!----- order area stop ----->
            </div>
          </div>

        </div>
      </div>
    </div>

    <b-modal id="countDownModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true">
      <template>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{
          $t("booking.go-product-page")
        }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="gotoProductPage(); ok()">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
    <b-modal id="actionModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true" :hide-footer="true">
      <template>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
        </div>
      </template>
      <template>
        <h5 :class="[lang == 'he' ? 'text-right' : 'text-left']">{{ $t('booking.modal-delaying') }}</h5>
        <div class="actionContainer justify-content-between">
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToContinue')"><img :src="`${speedSizeDomain}/assets/img/next.jpg`"
                  class="col-6 icon_box" width="25" height="25" alt="next" />
                <h6 class="m-auto">{{ $t('booking.modal-continue') }}</h6>
              </b-link>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToCall')"><img :src="`${speedSizeDomain}/assets/img/phoneCall.png`"
                  class="col-6 icon_box " width="25" height="25" alt="phone-call" />
                <h6 class="m-auto">{{ $t('booking.modal-call', { phoneNumber: phoneNumber }) }}</h6>
              </b-link>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToBack')"><img :src="`${speedSizeDomain}/assets/img/previous.jpg`"
                  class="col-6 icon_box " width="25" height="25" alt="previous" />
                <h6 class="m-auto">{{ $t('booking.modal-back') }}</h6>
              </b-link>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal id="extraLinkInfo" centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'" :ok-only="true"
      :hide-footer="true">
      <template>
        <iframe id="linkInformation" title="Extra Inforamtion" :src="linkInfo">
        </iframe>
      </template>
    </b-modal>
    <b-toast id="countDown" toaster="b-toaster-top-center" variant="warning" :autoHideDelay="maxSec * 1000" solid
      v-model="showCountDown" :classes="[lang == 'he' ? 'rtl' : 'ltr']">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
        </div>
      </template>
      {{ $t("booking.go-product-page") }}
    </b-toast>
    <b-modal id="actionRetryDebit" centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true" :hide-footer="true">
      <template>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice-retry') }}</h3>
        </div>
      </template>
      <template>
        <payer-information @updatePayer="updatingPayer" v-if="!bypassPaymentState" />
        <div :class="['d-flex m-3 w-25', lang == 'he' ? 'float-left' : 'float-right']">
          <b-button class="m-3" block @click="retryBookWithNewPayerInfo"
            variant="outline-success">{{ $t("booking.retry-booking") }}</b-button>
          <b-button class="m-3" block @click="$bvModal.hide('actionRetryDebit')"
            variant="outline-success">{{ $t("booking.close") }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BToast, BModal, BButton, BLink } from 'bootstrap-vue';

import TemplateFlightApp from './TemplateFlightApp';


export default {
  components: {
    BToast,
    BModal,
    BButton,
    BLink,
    MobileHeadBannerApp: () => import('@/components/booking/atoms/MobileHeadBannerApp'),
    UserListFlightApp: () => import('@/components/booking/atoms/UserListFlightApp'),
    PayerInformation: () => import('@/components/booking/atoms/PayerInformation'),
    FlightItemApp: () => import('@/components/productPage/atoms/FlightItemApp'),
    BaggageListApp: () => import('@/components/booking/atoms/BaggageListApp'),
    PayerInformationApp: () => import('@/components/booking/atoms/PayerInformationApp'),
    InternalAgentInfo: () => import('@/components/booking/InternalAgentInfo'),
    // MemberLogin: () => import('@/components/atoms/MemberLogin'),
    // MemberRegister: () => import('@/components/atoms/MemberRegister'),
  },
  extends: TemplateFlightApp,
};
</script>
