<template>
  <div>
    <div class="container" v-if="device === 'mobile'">
      <PriceOne v-for="(item, index) in roomsList" :key="index" :data="item" :index="index" :hotels="hotels"
        :translations="translations" @closed="removeItem" @update="updateItem" @setPriceLoading="getPriceLoading" />
      <div class="chooseroom_footer">
        <div class="chooseroom_footer_top d-flex justify-content-between">
          <a v-if="isMaccabiAgency"></a>
          <a href="#" onclick="return false;" @click="addRoom" class="btn btn-link" :disable="priceLoading" v-else>
            <b-spinner small type="grow" v-if="priceLoading"></b-spinner>
            <i class="fas fa-plus" v-else></i> {{ $t('product-page.add-room') }}
          </a>
          <div class="pricebox">
            {{ $t('product-page.total-per-passenger') }}
            <strong>{{ getPriceWithSymbol(product.cc, averagePrice) }}</strong>
            {{ $t('product-page.total-pay') }}
            <span>{{ getPriceWithSymbol(product.cc, totalPrice) }}</span>
          </div>
        </div>
        <button class="btn btn-primary d-block w-100 mt-3 text-center" :disabled="disableBook" @click="gotoBook">
          {{ $t('product-page.order') }}
        </button>
        <!-- <a href="#" @click="gotoBook" :disabled="disableBook" class="btn btn-primary d-block w-100 mt-3 text-center">
          {{ $t('product-page.order') }}
        </a> -->
      </div>
    </div>

    <div class="vacationprice_area" v-if="device === 'desktop'">
      <h3>
        {{ packageType }}
      </h3>
      <div class="flight-details">
        <div class="hldr">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="flight-details-leftbox">
                <h4 :style="lang !== 'he' ? 'direction: ltr;' : ''">
                  {{ personString }}, <span dir="ltr">{{ daysString }} </span>
                </h4>
                <div class="card cardone">
                  <PriceOne v-for="(item, index) in roomsList" :key="index" :data="item" :index="index" :hotels="hotels" :showRateRoom="roomsList.length>1"
                    :translations="translations" @closed="removeItem" @update="updateItem"
                    @setPriceLoading="getPriceLoading" />
                  <button type="button" class="add-field" @click="addRoom" :disabled="addRoomDisable || priceLoading" v-if="!isMaccabiAgency">
                    <i class="far fa-plus-square"></i>
                    <b-spinner small type="grow" v-if="priceLoading"></b-spinner>
                    {{ $t('product-page.add-room')}}
                    <i class="fas fa-hospital-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="flight-details-leftbox">
                <h4>{{ $t('product-page.vacation-price') }}</h4>
                <div class="card cardtwo">
                  <PriceTwo v-for="(item, index) in roomsList" :key="index" :room="item" :index="index"
                    @closed="removeItem" />
                  <div class="card-footer">
                    <div class="d-flex justify-content-between">
                      <div class="p-2">
                        {{ $t('product-page.special-price') }}
                      </div>
                      <div class="p-2">{{ getPriceWithSymbol(product.cc, totalPrice) }}</div>
                    </div>
                  </div>
                </div>
                <div class="addfooterbox d-flex justify-content-between">
                  <button class="btn btn-primary" :disabled="disableBook" @click="gotoBook">
                    {{ $t('product-page.book-button') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { BSpinner } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';
import googleAnalytics from '@/utils/googleAnalytics';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  name: 'price-area',
  components: {
    BSpinner,
    PriceOne: () => import('@/components/productPage/PriceOne'),
    PriceTwo: () => import('@/components/productPage/PriceTwo'),
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    hotelName: {
      type: String,
      default: '',
    },
    destinationCityName: {
      type: String,
      default: '',
    },
    includesText: {
      type: String,
      default: '',
    },
    priceTicket: {
      type: Number,
      default: 0,
    },
    beforePage: {
      type: String,
      default: '',
    },
  },
  mixins: [gMixin, googleAnalytics],
  data() {
    return {
      searchQuery: '',
      query: null,

      personString: '',

      roomsList: [],
      maxRoomCount: 5,
      addRoomDisable: false,
      priceLoading: false,

      roomBasisCode: null,
      adult: 0,
      child: 0,
      infant: 0,
      totalPrice: 0,
      averagePrice: 0,
      translations: [],

      disableBookDiscount: false,
      disableBook: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      typeChannel: 'GET_TYPE_CHANNEL',
      flights: 'GET_FLIGHT_ID',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      isMobileApp: 'GET_STATE_MOBILE_APP',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      isMaccabiFull: 'GET_IS_MACCABI_FULL',
    }),
    packageType() {
      const result = (this.categoryCode === 'Organize_tour_packages')
        ? `${this.$t('product-page.organized-trip')} ( ${this.$t('product-page.organized-trip-price-items')})`
        : `${this.$t('product-page.includes-message')}${this.includesText}`;
      return result;
    },
    categoryCode() {
      return this.product?.category?.code ?? '';
    },
    daysString() {
      return this.getBetweenDateString(this.query.dateFrom, this.product.hotel_shift_1, this.product.duration_1, this.product.hotels[0].duration);
    },
    disableBookingOnAPP() {
      return this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP;
    },
  },
  watch: {
    roomsList() {
      this.addRoomDisable = this.roomsList.length === this.maxRoomCount;
      this.updateTotalPrice();
    },
    lang() {
      this.personString = this.getPersonString(
        this.adult,
        this.child,
        this.infant,
      );
    },
    priceTicket() {
      const totalPriceRooms = this.roomsList.reduce((a, b) => +a + +b.totalPrice, 0);
      this.totalPrice = totalPriceRooms + this.priceTicket;
      this.product.priceTotal = this.totalPrice;
      window.sessionStorage.setItem('production', JSON.stringify(this.product));
    },
  },
  beforeMount() {
    const roomList = this.beforePage === 'booking' && window.sessionStorage.getItem('roomList') ? JSON.parse(window.sessionStorage.getItem('roomList')) : null;

    this.hotels = this.product.hotels;
    this.translations = this.product.translations;

    this.query = this.$route.query;
    const { adult, child, infant } = this.query;
    this.adult = Number(adult);
    this.child = Number(child);
    this.infant = Number(infant);

    this.personString = this.getPersonString(
      this.adult,
      this.child,
      this.infant,
    );

    if (roomList?.roomList) {
      this.roomsList = [...roomList.roomList];
    } else {
      this.roomsList.push(this.getOneItem());
    }
  },
  methods: {
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    getBetweenDateString(pFromDate, pShift, pDuration, pNight) {
      const checkIn = dayjs(pFromDate).add(Number(pShift), 'day'),
        checkOut = checkIn.add(Number(pDuration), 'day');
      let tripDuration = '', betweenDateString = '';
      if (this.lang === 'he') {
        tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} - ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${pNight} ${this.$t('search-result.night')})`;
        betweenDateString = tripDuration;
      } else {
        tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} -
            ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${pNight} ${this.$t('search-result.night')})`;
        betweenDateString = tripDuration;
      }
      return betweenDateString;
    },
    getPersonString(adult, child, infant) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    removeItem(index) {
      this.roomsList.splice(index, 1);
    },
    async addRoom() {
      if (!this.addRoomDisable && !this.priceLoading && !this.isMaccabiAgency) {
        this.roomsList.push(this.getOneItem());
        this.updateRoomsState();
      }
    },
    updateRoomsState() {
      const paxes = {
        adult: 0, child: 0, infant: 0,
      };
      this.roomsList.forEach((room) => {
        paxes.adult += room.adult;
        paxes.child += room.child;
        paxes.infant += room.infant;
      });
      this.$emit('message', { kind: 'upgradeRooms', paxes });
    },
    async updateItem(priceInfo) {
      if (priceInfo.success) {
        // if (priceInfo.price !== 0) {
        const flagUpdateBasis = this.roomBasisCode ? priceInfo.roomBasis !== this.roomBasisCode.value : false;
        for (const key in this.roomsList[priceInfo.index]) {
          this.roomsList[priceInfo.index][key] = priceInfo[key];
        }
        if (flagUpdateBasis) {
          for (let i = 0; i < this.roomsList.length; i += 1) {
            this.roomsList[i].roomBasis = priceInfo.roomBasis;
          }
        }
        this.averagePrice = priceInfo.price;
        this.updateTotalPrice();
        this.roomBasisCode = priceInfo.roomBasisCode;
        this.updateRoomsState();

        this.$emit('sendPrice', this.getPriceWithSymbol(this.product.cc, this.averagePrice));
      } else {
        this.disableBookDiscount = true;
        this.$emit('mesage', priceInfo.message);
      }
    },
    getOneItem() {
      return {
        hotelId: '',
        packSelectionId: this.product.packSelectionId,
        roomClass: { text: '', value: '' },
        roomType: { text: '', value: '' },
        roomBasis: this.roomBasisCode ? this.roomBasisCode.value : '',
        roomBasisCode: this.roomBasisCode,
        price: 0,
        adult: 0,
        child: 0,
        infant: 0,
        totalPrice: 0,
        oldPrice: 0,
        oldTotal: 0,
        priceInf: 0,
        cc: this.product.cc,
      };
    },
    updateTotalPrice() {
      const totalPriceRooms = this.roomsList.reduce((a, b) => +a + +b.totalPrice, 0);
      this.totalPrice = totalPriceRooms + this.priceTicket;
      this.product.priceTotal = this.totalPrice;
      window.sessionStorage.setItem('production', JSON.stringify(this.product));
      this.$store.dispatch('UPDATE_ROOM_LIST', { roomList: this.roomsList });
    },
    getPriceLoading(isLoading) {
      this.priceLoading = isLoading;
      this.disableBook = isLoading || this.disableBookDiscount || this.isMaccabiFull;
    },
    async gotoBook() {
      if (this.disableBook) return;
      const selectedRooms = [],
        gHotel = this.product.hotels[0];
      let discountedTotal = 0, undiscountedTotal = 0, totalPerson = 0;
      this.roomsList.forEach((room) => {
        const roomData = {
          hotelId: Number(room.hotelId),
          cityCode: gHotel.city,
          hotelSupplierCode: gHotel.Supplier,
          basisCode: room.roomBasis,
          rooms: {
            roomClassCode: room.roomClass.value,
            roomTypeCode: room.roomType.value,
            accomodation: 1,
            bBCots: Number(room.infant),
            paxIds: [],
          },
        };
        discountedTotal += room.totalPrice - room.infant * room.priceInf;
        undiscountedTotal += room.oldTotal - room.infant * room.priceInf;
        totalPerson += (room.adult + room.child + room.infant);

        selectedRooms.push(roomData);
      });

      this.product.hotel = selectedRooms;
      this.product.packageId = Number(this.roomsList[0].roomBasisCode.packId);
      this.product.duration = Number(this.product.duration_1);
      this.product.discountPercent = (discountedTotal !== undiscountedTotal) ? ((undiscountedTotal - discountedTotal) * 100) / undiscountedTotal : null;
      this.product.price = Number(this.product.priceTotal);
      this.product.priceNIS = 0;
      this.product.currency = this.product.cc;
      this.product.ensureCancellation = false;
      const data = {
        data: this.product,
        sendingStage: 'init',
      };
      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);

      if (this.availableEmitEventGA4ByDomain()) {
        const affiliation = this.isMobileApp ? 'BO NAUF' : 'Flying Carpet';
        const currencyCode = this.convertCurrencyCode(this.product.cc);
        this.gtag('event', 'view_item', {
          currency: currencyCode,
          value: this.totalPrice,
          items: [
            {
              item_id: response.data.bookingTransactionId,
              item_name: `${this.hotelName} - ${this.destinationCityName}`,
              affiliation,
              coupon: '',
              discount: '',
              index: 0,
              item_brand: '',
              item_category: this.destinationCityName,
              item_category2: this.getCheckInOut(),
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: this.totalPrice,
              quantity: 1,
            },
          ],

          // items: [
          //   {
          //     id: response.data.bookingTransactionId, // please assign number
          //     name: `${this.hotelName} - ${this.destinationCityName}`,
          //     list_name: '',
          //     brand: '',
          //     category: '',
          //     variant: '',
          //     list_position: 1,
          //     quantity: 1,
          //     price: this.totalPrice,
          //   },
          // ],
        });
      }

      if (response.success) {
        this.product.priceNIS = response.data.priceNis;
        window.sessionStorage.setItem('production', JSON.stringify(this.product));
        window.sessionStorage.setItem('BookingDataID', response.data.bookingTransactionId);
        window.sessionStorage.setItem('retryState', 'null');
        window.sessionStorage.setItem('cancellationInfo', JSON.stringify({
          cancellationAllowed: this.product.cancellationAllowed,
          cancelInsuranceFee: response.data.cancelInsuranceFee,
          cancelInsuranceFeeNis: response.data.cancelInsuranceFeeNis,
          totalPerson,
        }));
        const body = {
          packID: this.product.packageId,
          laID: this.query.laId,
          flights: this.flights,
          marketerId: this.query.marketerId,
          channel: this.typeChannel,
        };
        if (this.$route.query.utm_source) body.utm_source = this.$route.query.utm_source;
        if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';
        let path = '/booking/hotel-flight/web';
        if (this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP) {
          path = '/booking/hotel-flight/oldapp';
        }
        this.$router.push({ path, query: body });
      } else {
        window.sessionStorage.setItem('BookingDataID', '');
      }
    },
  },
};
</script>
<style scoped>
button:disabled,
[type='button']:disabled,
[type='reset']:disabled,
[type='submit']:disabled {
  cursor: default;
  opacity: 0.2;
}
</style>
<style>
button.js-del-row {
  top: -5px;
}

@media (max-width: 600px) {
  div[id^='upgrade-'] .modal-dialog {
    width: 96%;
  }

  div[id^='upgrade-'] table th,
  div[id^='upgrade-'] table td {
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .flightpagelist.filterItem {
    margin-top: 1em;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 5px;
  }
}
</style>
